import React, { useEffect, useLayoutEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { graphql, navigate, useStaticQuery, withPrefix } from "gatsby"
import { useQuery } from "@apollo/react-hooks"
import { useMediaPredicate } from "react-media-hook"
import Select from "react-select"
import ReactMarkdown from "react-markdown/with-html"
import Slider from "react-slick";

import { FIND_BY_PROPERTY_TYPE } from "../graphql/smartmove-graphql"

import "../graphql/fragments"

import SEO from "../components/seo"
import Layout from "../components/layout"

import { Section, Container } from "../components/grid/grid"
import { CardProject } from "../components/card-project/card-project"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const changeParentSlug = (slug) => {
  switch(slug) {
    case "infiniteliving-shophouse":
      slug = "shophouse";
      break;
    case "infiniteliving-residential":
      slug = "residential";
      break;
    case "infiniteliving-kavling":
      slug = "kavling";
      break;
    case "infiniteliving-apartment":
      slug = "apartment";
      break;
  }
  return slug
}

const ProjectByPropertyTypeInfiniteLiving = ({ pageContext, location }) => {

  const staticData = useStaticQuery(graphql`
    query {
      wordPress {
        propertyType(id: "infiniteliving", idType: SLUG) {
          name
          description
          children {
            nodes {
              name
              slug
              projects(first:1000){
                nodes {
                  slug
                  title
                  excerpt
                  featuredImage {
                    sourceUrl
                  }
                  priceProduct {
                    price
                  }
                  locations {
                    nodes {
                      name
                      slug
                    }
                  }
                }
              }
            }
          }
        }
        facilities(first: 100) {
          nodes {
            name
            slug
            facilityId
            projects {
              nodes {
                propertyTypes {
                  nodes {
                    slug
                    name
                  }
                }
              }
            }
          }
        }

        locations(first: 100) {
          nodes {
            locationId
            slug
            name
          }
        }

        propertyTypes(first: 100) {
          nodes {
            name
            slug
            propertyTypeId
            children {
              nodes {
                name
                slug
              }
            }
          }
        }
      }
    }
  `)

  const { typeName, typeSlug } = pageContext
  const locations = []
  const locSet = new Set()
  const wordPress = staticData?.wordPress?.propertyType

  for (const loc of pageContext.locations) {
    locSet.add(JSON.stringify(loc))
  }

  for (const loc of locSet) {
    locations.push(JSON.parse(loc)) 
  }

  const HelmetMetaList = () => {
    if(changeParentSlug(typeSlug) === "kavling"){
      return (
        <Helmet>
          <meta name="description" content="Sinar Mas Land Tawarkan kavling dengan lokasi strategis dan murah. Dapatkan Promo kavling dari Program Smart Move yang berlangsung dari bulan Februari sampai Desember 2023." />
          <meta name="news_keywords" content="Smart Move, Program Smart Move Kavling, Tanah Kavling Strategis, Kavling, Jual Kavling Terbaru, Harga Kavling Terbaru" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-touch-fullscreen" content="yes" />
          <meta name="copyright" content="2023 | Sinar Mas Land" />
          <meta name="robots" content="index, follow" />
          <meta content="Sinar Mas Land" name="author" />
          <meta content="id" name="language" />
          <meta content="id" name="geo.country" />
          <meta content="Indonesia" name="geo.placename" />
  
          <meta property="fb:app_id" content="401011690922768" data-react-helmet="true" />
          <meta property="og:type" content="article" />
          <meta property="og:url" content="https://sinarmasland.com/infinitelivig/kavling" />
          <meta property="og:title" content="Infinite Living | Kavling Sinar Mas Land" />
          <meta property="og:image" content="https://www.sinarmasland.com/app/uploads/2023/01/510x510-no-cim-01-01.jpg" />
            
          <meta property="og:image:type" content="image/jpeg" data-react-helmet="true" />
          <meta property="og:image:width" content="1200" data-react-helmet="true" />
          <meta property="og:image:height" content="630" data-react-helmet="true" />
          <meta property="og:description" content="Sinar Mas Land Tawarkan kavling dengan lokasi strategis dan murah. Dapatkan Promo kavling dari Program Smart Move yang berlangsung dari bulan Februari sampai Desember 2023." />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@sinarmas_land" />
          <meta name="twitter:site:id" content="@sinarmas_land" />
          <meta name="twitter:creator" content="@sinarmas_land" />
          <meta name="twitter:image" content="https://www.sinarmasland.com/app/uploads/2023/01/510x510-no-cim-01-01.jpg" />
          <meta name="twitter:image:src" content="https://www.sinarmasland.com/app/uploads/2023/01/510x510-no-cim-01-01.jpg" />
          <meta name="twitter:description" content="Sinar Mas Land Tawarkan kavling dengan lokasi strategis dan murah. Dapatkan Promo kavling dari Program Smart Move yang berlangsung dari bulan Februari sampai Desember 2023." />
          <link rel="canonical" href="https://sinarmasland.com/infiniteliving/kavling"  />

          <script src={withPrefix("slider.js")} type="text/javascript" />
          <body className="bd-page" />
        </Helmet>
      )
    }else if(changeParentSlug(typeSlug) === "shophouse"){
      return(
        <Helmet>
          <meta name="description" content="Sinar Mas Land Tawarkan Ruko dengan lokasi strategis. Dapatkan Promo Ruko dari Program Smart Move yang berlangsung dari bulan Februari sampai Desember 2023." />
          <meta name="news_keywords" content="Smart Move, Program Smart Move Ruko, Ruko Strategis, Shophouse, Jual Ruko Terbaru, Harga Ruko Terbaru" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-touch-fullscreen" content="yes" />
          <meta name="copyright" content="2023 | Sinar Mas Land" />
          <meta name="robots" content="index, follow" />
          <meta content="Sinar Mas Land" name="author" />
          <meta content="id" name="language" />
          <meta content="id" name="geo.country" />
          <meta content="Indonesia" name="geo.placename" />
          <meta http-equiv="content-language" content="In-Id" />

          <meta property="fb:app_id" content="401011690922768" data-react-helmet="true" />
          <meta property="og:type" content="article" />
          <meta property="og:url" content="https://sinarmasland.com/infiniteliving/shophouse" />
          <meta property="og:title" content="Infinite Living | Shophouse Sinar Mas Land" />
          <meta property="og:image" content="https://www.sinarmasland.com/app/uploads/2023/01/510x510-no-cim-01-01.jpg" />
            
          <meta property="og:image:type" content="image/jpeg" data-react-helmet="true" />
          <meta property="og:image:width" content="1200" data-react-helmet="true" />
          <meta property="og:image:height" content="630" data-react-helmet="true" />
          <meta property="og:description" content="Sinar Mas Land Tawarkan Ruko dengan lokasi strategis.  Dapatkan Promo Ruko dari Program Smart Move yang berlangsung dari bulan Februari sampai Desember 2023." />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@sinarmas_land" />
          <meta name="twitter:site:id" content="@sinarmas_land" />
          <meta name="twitter:creator" content="@sinarmas_land" />
          <meta name="twitter:image" content="https://www.sinarmasland.com/app/uploads/2023/01/510x510-no-cim-01-01.jpg" />
          <meta name="twitter:image:src" content="https://www.sinarmasland.com/app/uploads/2023/01/510x510-no-cim-01-01.jpg" />
          <meta name="twitter:description" content="Sinar Mas Land Tawarkan Rumah Modern, Mewah, Strategis. Dapatkan Promo Rumah dari Program Smart Move yang berlangsung dari bulan Februari sampai Desember 2023." />
          <link rel="canonical" href="https://sinarmasland.com/infiniteliving/shophouse"/>

          <script src={withPrefix("slider.js")} type="text/javascript" />
          <body className="bd-page" />
        </Helmet>
      )
    }else if(changeParentSlug(typeSlug) === "residential"){
      return(
        <Helmet>
          <meta name="description" content="Sinar Mas Land Tawarkan Rumah Modern, Mewah, Strategis.  Dapatkan Promo Rumah dari Program Smart Move yang berlangsung dari bulan Februari sampai Desember 2023." />
          <meta name="news_keywords" content="Rumah Smart Move, Program Smart Move, Rumah Cluster, Jual Rumah Terbaru, Harga Rumah Terbaru, Rumah Modern" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-touch-fullscreen" content="yes" />
          <meta name="copyright" content="2023 | Sinar Mas Land" />
          <meta name="robots" content="index, follow" />
          <meta content="Sinar Mas Land" name="author" />
          <meta content="id" name="language" />
          <meta content="id" name="geo.country" />
          <meta content="Indonesia" name="geo.placename" />
          <meta http-equiv="content-language" content="In-Id" />

          <meta property="fb:app_id" content="401011690922768" data-react-helmet="true" />
          <meta property="og:type" content="article" />
          <meta property="og:url" content="https://sinarmasland.com/infiniteliving/residential" />
          <meta property="og:title" content="Infinite Living | Residential Sinar Mas Land" />
          <meta property="og:image" content="https://www.sinarmasland.com/app/uploads/2023/01/510x510-no-cim-01-01.jpg" />
            
          <meta property="og:image:type" content="image/jpeg" data-react-helmet="true" />
          <meta property="og:image:width" content="1200" data-react-helmet="true" />
          <meta property="og:image:height" content="630" data-react-helmet="true" />
          <meta property="og:description" content="Sinar Mas Land Tawarkan Rumah Modern, Mewah, Strategis.  Dapatkan Promo Rumah dari Program Smart Move yang berlangsung dari bulan Februari sampai Desember 2023." />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@sinarmas_land" />
          <meta name="twitter:site:id" content="@sinarmas_land" />
          <meta name="twitter:creator" content="@sinarmas_land" />
          <meta name="twitter:image" content="https://www.sinarmasland.com/app/uploads/2023/01/510x510-no-cim-01-01.jpg" />
          <meta name="twitter:image:src" content="https://www.sinarmasland.com/app/uploads/2023/01/510x510-no-cim-01-01.jpg" />
          <meta name="twitter:description" content="Sinar Mas Land Tawarkan Rumah Modern, Mewah, Strategis.  Dapatkan Promo Rumah dari Program Smart Move yang berlangsung dari bulan Februari sampai Desember 2023." />
          <link rel="canonical" href="https://sinarmasland.com/infiniteliving/residential"/>

          <script src={withPrefix("slider.js")} type="text/javascript" />
          <body className="bd-page" />
        </Helmet>
      )
    }else if(changeParentSlug(typeSlug) === "apartment"){
      return(
        <Helmet>
          <meta name="description" content="Sinar Mas Land Tawarkan Apartment Mewah dengan Program Smart Move. Program ini berlangsung dari bulan Februari sampai Desember 2023." />
          <meta name="news_keywords" content="Apartment Smart Move, Program Smart Move Apartment, Apartment, Jual Apartment Terbaru, Harga Apartment" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-touch-fullscreen" content="yes" />
          <meta name="copyright" content="2023 | Sinar Mas Land" />
          <meta name="robots" content="index, follow" />
          <meta content="Sinar Mas Land" name="author" />
          <meta content="id" name="language" />
          <meta content="id" name="geo.country" />
          <meta content="Indonesia" name="geo.placename" />
          <meta http-equiv="content-language" content="In-Id" />

          <meta property="fb:app_id" content="401011690922768" data-react-helmet="true" />
          <meta property="og:type" content="article" />
          <meta property="og:url" content="https://sinarmasland.com/infiniteliving/apartment" />
          <meta property="og:title" content="Infinite Living | Apartment Sinar Mas Land" />
          <meta property="og:image" content="https://www.sinarmasland.com/app/uploads/2023/01/510x510-no-cim-01-01.jpg" />
            
          <meta property="og:image:type" content="image/jpeg" data-react-helmet="true" />
          <meta property="og:image:width" content="1200" data-react-helmet="true" />
          <meta property="og:image:height" content="630" data-react-helmet="true" />
          <meta property="og:description" content="Sinar Mas Land Tawarkan Apartment Mewah dengan Program Smart Move. Program ini berlangsung dari bulan Februari sampai Desember 2023." />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@sinarmas_land" />
          <meta name="twitter:site:id" content="@sinarmas_land" />
          <meta name="twitter:creator" content="@sinarmas_land" />
          <meta name="twitter:image" content="https://www.sinarmasland.com/app/uploads/2023/01/510x510-no-cim-01-01.jpg" />
          <meta name="twitter:image:src" content="https://www.sinarmasland.com/app/uploads/2023/01/510x510-no-cim-01-01.jpg" />
          <meta name="twitter:description" content="Sinar Mas Land Tawarkan Apartment Mewah dengan Program Smart Move. Program ini berlangsung dari bulan Februari sampai Desember 2023." />
          <link rel="canonical" href="https://sinarmasland.com/infiniteliving/apartment"/>

          <script src={withPrefix("slider.js")} type="text/javascript" />
          <body className="bd-page" />
        </Helmet>
      )
    }else{
      return(
        <Helmet>
          <script src={withPrefix("slider.js")} type="text/javascript" />
          <body className="bd-page" />
        </Helmet>
      )
    }
  }

  const { data: projectsByPropertyType, loading } = useQuery(FIND_BY_PROPERTY_TYPE, {
    variables: { childrenSlug: typeSlug },
  })
  const resizeScreen = useMediaPredicate("(min-width: 992px)")
  let propertyTypesChecker = []
  let listOfImages = []
  let listImagesBanner = projectsByPropertyType?.propertyTypes?.nodes[0]?.children?.nodes[0]?.acfPropertyType?.sliderImageBannerWdesc
  let listImagesMobile = projectsByPropertyType?.propertyTypes?.nodes[0]?.children?.nodes[0]?.acfPropertyType?.sliderImageMobileWdesc

  const listPropertyTypesParent = [
    {label: "All Property", value: " "},
    {label: "Apartment", value: "apartment"},
    {label: "Kavling", value: "kavling"},
    {label: "Residential", value: "residential"},
    {label: "Shophouse", value: "shophouse"},
  ]

    const masterProjects = []
    const duplicatedProject = []
    projectsByPropertyType?.propertyTypes?.nodes.forEach(parent => {
      parent?.children?.nodes.forEach(children => {
        children?.projects?.nodes?.forEach((item) => {
          let slug = changeParentSlug(children.slug)
          masterProjects.push({...item, catName: children.name, catSlug: slug})

          item?.propertyTypes?.nodes?.forEach(propertyTypeList => {
            if (propertyTypeList.slug !== "infiniteliving") {
              propertyTypesChecker.push(propertyTypeList.slug)
            }
          })
        })
      })
    })

    wordPress?.children?.nodes.forEach(children =>
      children.projects.nodes.forEach(item =>{
        let slug = changeParentSlug(children.slug)
        duplicatedProject.push({...item, catName: children.name, catSlug: slug})
      })
    )

    if(resizeScreen){
      listImagesBanner &&
        listImagesBanner.map(item => {
          listOfImages.push({
            image: item?.image?.sourceUrl ? item?.image?.sourceUrl : "",
            url: item?.link?.url ? item?.link?.url : "#",
            title: item?.title ? item.title : "",
            description : item?.description ? item?.description : ""
          })
        })
    }else{
      listImagesMobile &&
        listImagesMobile.map(item => {
          listOfImages.push({
            image: item?.image?.sourceUrl ? item?.image?.sourceUrl : "",
            url: item?.link?.url ? item?.link?.url : "#",
            title: item?.title ? item?.title : "",
            description : item?.description ? item?.description : ""
          })
        })
    }

  const offset = 0
  const limit = 12
  const perPageRecommended = 6
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(limit)
  const [filteredProjects, setFilteredProjects] = useState()
  const [recommendedProjects, setRecommendedProjects] = useState()
  const [filteredLocations, setFilteredLocations] = useState("")
  const [filterKey, setFilterKey] = useState("")
  const [filteredPropertyTypes, setFilteredPropertyTypes] = useState(listPropertyTypesParent.filter(item => item.label === typeName)[0])

  useLayoutEffect(() => {
      setFilteredProjects(masterProjects)  
      setRecommendedProjects(duplicatedProject)  
  }, [loading])

  useEffect(() => {
    if(filteredPropertyTypes?.value !== undefined && filteredLocations?.value !== undefined){
      navigate("/infiniteliving/"+filteredPropertyTypes?.value+"/"+filteredLocations?.value)
    }else if(filteredPropertyTypes?.value !== undefined && filteredLocations?.value === undefined){
      if(filteredPropertyTypes?.value === " "){
        navigate("/infiniteliving/")
      }else{
        navigate("/infiniteliving/"+filteredPropertyTypes?.value, {
          state: {
            propertyType: filteredPropertyTypes?.value,
          },
        })
      }
    }else if(filteredPropertyTypes?.value === undefined && filteredLocations?.value !== undefined){
      if(filteredLocations.value === " "){
        navigate("/infiniteliving/all-property/all-locations")
      }else{
        navigate("/infiniteliving/all-property/"+filteredLocations?.value, {
          state: {
            location: filteredLocations?.value,
          },
        })
      }
    }
  }, [filteredPropertyTypes, filteredLocations])

  useEffect(() => {
    let listOfImages = []
    let listImagesBanner = projectsByPropertyType?.propertyTypes?.nodes[0]?.children?.nodes[0]?.acfPropertyType?.sliderImageBannerWdesc
    let listImagesMobile = projectsByPropertyType?.propertyTypes?.nodes[0]?.children?.nodes[0]?.acfPropertyType?.sliderImageMobileWdesc

    if(resizeScreen){
      listImagesBanner &&
        listImagesBanner.map(item => {
          listOfImages.push({
            image: item?.image?.sourceUrl ? item?.image?.sourceUrl : "",
            url: item?.link?.url ? item?.link?.url : "#",
            title: item?.title ? item.title : "",
            description : item?.description ? item?.description : ""
          })
        })
      document.querySelector(".animated").style.marginTop = "0px"
    }else{
      listImagesMobile &&
        listImagesMobile.map(item => {
          listOfImages.push({
            image: item?.image?.sourceUrl ? item?.image?.sourceUrl : "",
            url: item?.link?.url ? item?.link?.url : "#",
            title: item?.title ? item.title : "",
            description : item?.description ? item?.description : ""
          })
        })
      document.querySelector(".animated").style.marginTop = "60px"
    }
    
    // document.querySelector(".animated").style.marginTop = "0px"
    // document.querySelector(".sliderWfh").style.paddingTop = "0px"
    // document.querySelector(".sliderWfh").style.paddingBottom = "0px"
    // document.querySelector(".form-select .select__control").style.background = "transparent"
    document.querySelector("#lang-wrapper").classList.remove('d-flex')
    document.querySelector("#lang-wrapper").classList.add('d-none')
  }, [])

  const locationHandler = e => {
    setFilteredLocations(e)
  }
  const propertyTypesHandler = e => {
    setFilteredPropertyTypes(e)
  }

  const handleLoadMore = () => {
    const nextPage = page + 1
    const nextPerPage = nextPage * limit
    setPage(nextPage)
    setPerPage(nextPerPage)
  }

  const handlerFilterProject = () => {
    let projects
    let duplicatedList

    if (filterKey != "") {
      projects = masterProjects.filter((item) => item.title.toLowerCase().indexOf(filterKey.toLowerCase()) > -1)
      if (projects.length === 0 ) {
        duplicatedList = duplicatedProject.filter((item) => item.title.toLowerCase().indexOf(filterKey.toLowerCase()) > -1)
      }
    } else {
      projects = masterProjects
    }

    setFilteredProjects(projects)
    setRecommendedProjects(duplicatedList)
  }

  const settings = {
    autoplay: listOfImages.length > 1 ? true : false,
    autoplaySpeed: listOfImages.length > 1 ? 3000 : 0,
    accessibility: true,
    dots: true,
    dotsClass: "slick-dots dots-lg",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  }

    useEffect(() => {
        // store available locations per type
        sessionStorage.setItem("locations", JSON.stringify({[typeName] : locations, ...JSON.parse(sessionStorage.getItem("locations"))}))
    }, [])


  useEffect(() => {
    let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")
    const locPathName = location.pathname
    let pathIdentifier = "/infiniteliving/"

    if (locPathName.indexOf(pathIdentifier) > -1) {  
      if(!resizeScreen){
        document.querySelector("#navbarMobile .logo-secondary").style.cssText = `display:none !important`
        document.querySelector("#navbarMobile .logo-main").style.cssText = `display:block !important`
      }else{
        document.querySelector("#desktopNav .logo-secondary").style.cssText = `display:none !important`
        document.querySelector("#desktopNav .logo-main").style.cssText = `display:block !important`
      }
    }

    for (let i = 0; i < navbarDesktop.length; i++) {
      navbarDesktop[i].style.color = "#000"
    }
  }, [])

  return (
    <Layout location={location} currentLocation={location.pathname}>
      <SEO title={`Infinite Living | ${typeName}`} url={location?.href} location={location.href}/>
      {/* <Helmet>
        <script src={withPrefix("slider.js")} type="text/javascript" />
        <body className="bd-page" />
      </Helmet> */}
      <HelmetMetaList />
      <Section className="header-smartmove-banner p-0 overflow-x-hidden" style={{padding:"0px"}}>
        <Slider {...settings}>
          {listOfImages.length > 0 &&(
            listOfImages.map((item, i) => {
              return(
                <div key={i}>
                  <img className="slider-img-smartmove" src={item.image} onClick={()=> window.open(item.url, "_blank")}/>
                  <Section>
                    <Container className="container-md">
                      <h2>{item.title}</h2>
                      <p><ReactMarkdown key={item.description} source={item.description} escapeHtml={false} /></p>
                    </Container>
                  </Section>
                </div>
              )
            })
          )}
        </Slider>
        {/* {listOfImages.length < 1 &&(
          <CoverMain
            h1={`Smart Move - ${changeParentSlug(typeSlug)}`}
            img={desktopLp}
            alt={`Smart Move Banner`}
            className="cover-md cover-detail mt-0"
          >
            <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
          </CoverMain>
        )} */}
      </Section>
      {/* <Section>
        <Container className="container-md">
          <h2>{typeName}</h2>
          <p
            className="card-text"
            dangerouslySetInnerHTML={{
              __html:
              projectsByPropertyType?.propertyTypes?.nodes[0]?.children?.nodes[0]
                  ?.description,
            }}
          ></p>
        </Container>
      </Section> */}
      <Section className="main-by-property">
        <Container className="mb-3">
          <div className="row">
            <div className="col-md-12">
              <Container id="search-bar" className="mb-5">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    id="search-properti"
                    className="form-control"
                    placeholder="Cari properti disini"
                    value={filterKey}
                    onChange={(e) => setFilterKey(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => handlerFilterProject()}
                    >
                      Cari
                    </button>
                  </div>
                </div>
              </Container>
            </div>
            <div className="col-md-6">
              <div className="form-group d-flex align-items-center">
                <i className="fas fa-landmark form-icon-fa"></i>
                <Select
                  value={filteredPropertyTypes}
                  id="propertytypes"
                  className="form-select w-100"
                  classNamePrefix="select"
                  placeholder="Property Types"
                  options={listPropertyTypesParent}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e5e5e5",
                      primary: "#191919",
                    },
                  })}
                  onChange={e => propertyTypesHandler(e)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group d-flex align-items-center">
                <i className="fas fa-map-marker-alt form-icon-fa"></i>
                <Select
                  id="location"
                  className="form-select w-100"
                  classNamePrefix="select"
                  placeholder="Location"
                  options={locations}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e5e5e5",
                      primary: "#191919",
                    },
                  })}
                  onChange={e => locationHandler(e)}
                />
              </div>
            </div>
            <div className="col-md-12 mb-3 mt-3">
              <ul className="faq-nav">
                <li className="item">
                  <a className="faq-link" href="/infiniteliving/faq" style={{color: "#ab0204 !important"}}>
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Container>
        <div className="smartmove-list-projects">
          <div className="smartmove-bg"></div>
          {filteredProjects && (
            <div className="row col-md-12 m-0 p-0">
              {filteredProjects.slice(offset, perPage).map((item) => {
                return (
                  <div key={item.title} className="col-md-4 p-3">
                    <CardProject
                      title={item.title}
                      description={
                        item.excerpt ||
                        "Sinarmas Land is the largest and most diversed property developer in Indonesia. The company is known for its rich experience of more than 40 years in the field of property development"
                      }
                      img={
                        item.featuredImage
                          ? item.featuredImage.sourceUrl
                          : "https://sinarmasland.com/static/sml-plaza-6a6e8ebe7eb4ad249e70d5149c18fcc0.png"
                      }
                      link={
                        propertyTypesChecker.length > 0 
                          ? `/development/${propertyTypesChecker[0]}/${item.slug}`
                          : `/infiniteliving/${item.catSlug}/${item?.locations?.nodes[0]?.slug}/${item.slug}`
                      }
                      className="px-sm-down-0 ratio-1-1"
                      category={item.catName || null}
                      location={item.locations.nodes[0].name || null}
                    />
                  </div>
                )
              })}
            </div>
          )}
          <div className="col-md-12 pb-5 text-center">
            {filteredProjects && filteredProjects.length > perPage ? (
                <button 
                  className="btn btn-primary w-100 w-md-25"
                  onClick={handleLoadMore}
                >
                  Load More
                </button>
            ) : ""}
          </div>
          {filterKey !== "" && filteredProjects && filteredProjects.length === 0 && (
            <div className="row">
              <div className="col-md-12 mb-5 not-available-text">The project not available</div>
              <div className="col-md-12 mt-3 nb-3 text-left recommended-text">Recommended For You</div>
              {recommendedProjects.slice(offset, perPageRecommended).map((item) => {
                return(
                  <div key={item.title} className="col-md-4 p-3">
                    <CardProject
                      title={item.title}
                      description={
                        item.excerpt ||
                        "Sinarmas Land is the largest and most diversed property developer in Indonesia. The company is known for its rich experience of more than 40 years in the field of property development"
                      }
                      img={
                        item.featuredImage
                          ? item.featuredImage.sourceUrl
                          : "https://sinarmasland.com/static/sml-plaza-6a6e8ebe7eb4ad249e70d5149c18fcc0.png"
                      }
                      link={
                        propertyTypesChecker.length > 0 
                          ? `/development/${propertyTypesChecker[0]}/${item.slug}`
                          : `/infiniteliving/${item.catSlug}/${item?.locations?.nodes[0]?.slug}/${item.slug}`
                      }
                      className="px-sm-down-0 ratio-1-1"
                      category={item.catName || null}
                      location={item.locations.nodes[0].name || null}
                    />
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </Section>
    </Layout>
  )
}
export default ProjectByPropertyTypeInfiniteLiving
